import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { TransactionService } from '../service/TransactionService';

const Transaction = () => {
    let emptyTransaction = {
        rec_id: null,
        customer_id: '',
        type_id: '',
        description: '',
        created_date: '',
        reference: '',
        amount: 0,
        balance: 0
    };

    const [transactions, setTransactions] = useState([]);
    const [transactionDialog, setTransactionDialog] = useState(false);
    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [deleteTransactionsDialog, setDeleteTransactionsDialog] = useState(false);
    const [transaction, setTransaction] = useState(emptyTransaction);
    const [selectedTransactions, setSelectedTransactions] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const transactionService = new TransactionService();
        transactionService.getTransactions().then((response) => {
            setTransactions(response.data);
        });
    }, []);

    const openNew = () => {
        setTransaction(emptyTransaction);
        setSubmitted(false);
        setTransactionDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setTransactionDialog(false);
    };

    const hideDeleteTransactionDialog = () => {
        setDeleteTransactionDialog(false);
    };

    const hideDeleteTransactionsDialog = () => {
        setDeleteTransactionsDialog(false);
    };

    const saveTransaction = () => {
        setSubmitted(true);

        if (validateRecord(transaction)) {
            const transactionService = new TransactionService();
            if (transaction.rec_id == null) {
                transactionService.addTransaction(transaction).then((response) => {
                    setTransactions([...transactions, response.data]);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Transaction Created',
                        life: 3000,
                    });
                    setTransactionDialog(false);
                    setTransaction(emptyTransaction);
                }).catch((error) => {
                    console.error('Error saving new Transaction:', error);
                });
            } else {
                transactionService.updateTransaction(transaction, transaction.rec_id).then((response) => {
                    const updatedTransactions = transactions.map((t) => t.rec_id === response.data.rec_id ? transaction : t);
                    setTransactions(updatedTransactions);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Transaction Updated',
                        life: 3000,
                    });
                    setTransactionDialog(false);
                    setTransaction(emptyTransaction);
                }).catch((error) => {
                    console.error('Error updating Transaction:', error);
                });
            }
        }
    };

    const validateRecord = (record) => {
        return record !== null && record !== undefined;
    };

    const editTransaction = (transaction) => {
        setTransaction({ ...transaction });
        setTransactionDialog(true);
    };

    const confirmDeleteTransaction = (transaction) => {
        setTransaction(transaction);
        setDeleteTransactionDialog(true);
    };

    const deleteTransaction = () => {
        const transactionService = new TransactionService();
        transactionService.deleteTransaction(transaction.rec_id).then(() => {
            let updatedTransactions = transactions.filter((val) => val.rec_id !== transaction.rec_id);
            setTransactions(updatedTransactions);
            setDeleteTransactionDialog(false);
            setTransaction(emptyTransaction);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Transaction Deleted', life: 3000 });
        }).catch((error) => {
            console.error('Error deleting Transaction:', error);
        });
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteTransactionsDialog(true);
    };

    const deleteSelectedTransactions = () => {
        let _transactions = transactions.filter((val) => !selectedTransactions.includes(val));
        setTransactions(_transactions);
        setDeleteTransactionsDialog(false);
        setSelectedTransactions(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Transactions Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _transaction = { ...transaction };
        _transaction[`${name}`] = val;
        setTransaction(_transaction);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedTransactions || !selectedTransactions.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const recIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.rec_id}
            </>
        );
    };

    const customerIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer ID</span>
                {rowData.customer_id}
            </>
        );
    };

    const typeIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type ID</span>
                {rowData.type_id}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const createdDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created Date</span>
                {rowData.created_date}
            </>
        );
    };

    const referenceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reference</span>
                {rowData.reference}
            </>
        );
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData.amount}
            </>
        );
    };

    const balanceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Balance</span>
                {rowData.balance}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editTransaction(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteTransaction(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Transactions</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const transactionDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveTransaction} />
        </>
    );
    const deleteTransactionDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTransactionDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteTransaction} />
        </>
    );
    const deleteTransactionsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTransactionsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedTransactions} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={transactions}
                        selection={selectedTransactions}
                        onSelectionChange={(e) => setSelectedTransactions(e.value)}
                        dataKey="rec_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} transactions"
                        globalFilter={globalFilter}
                        emptyMessage="No transactions found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="rec_id" header="ID" body={recIdBodyTemplate}></Column>
                        <Column field="customer_id" header="Customer ID" sortable body={customerIdBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="type_id" header="Type ID" body={typeIdBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="description" header="Description" body={descriptionBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="created_date" header="Created Date" body={createdDateBodyTemplate} sortable headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="reference" header="Reference" body={referenceBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="amount" header="Amount" body={amountBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="balance" header="Balance" body={balanceBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={transactionDialog} style={{ width: '450px' }} header="Transaction Details" modal className="p-fluid" footer={transactionDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="customer_id">Customer ID</label>
                            <InputText id="customer_id" value={transaction.customer_id} onChange={(e) => onInputChange(e, 'customer_id')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.customer_id })} />
                            {submitted && !transaction.customer_id && <small className="p-invalid">Customer ID is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="type_id">Type ID</label>
                            <InputText id="type_id" value={transaction.type_id} onChange={(e) => onInputChange(e, 'type_id')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.type_id })} />
                            {submitted && !transaction.type_id && <small className="p-invalid">Type ID is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputText id="description" value={transaction.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.description })} />
                            {submitted && !transaction.description && <small className="p-invalid">Description is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="created_date">Created Date</label>
                            <InputText id="created_date" value={transaction.created_date} onChange={(e) => onInputChange(e, 'created_date')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.created_date })} />
                            {submitted && !transaction.created_date && <small className="p-invalid">Created Date is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="reference">Reference</label>
                            <InputText id="reference" value={transaction.reference} onChange={(e) => onInputChange(e, 'reference')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.reference })} />
                            {submitted && !transaction.reference && <small className="p-invalid">Reference is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="amount">Amount</label>
                            <InputText id="amount" value={transaction.amount} onChange={(e) => onInputChange(e, 'amount')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.amount })} />
                            {submitted && !transaction.amount && <small className="p-invalid">Amount is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="balance">Balance</label>
                            <InputText id="balance" value={transaction.balance} onChange={(e) => onInputChange(e, 'balance')} required autoFocus className={classNames({ 'p-invalid': submitted && !transaction.balance })} />
                            {submitted && !transaction.balance && <small className="p-invalid">Balance is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTransactionDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTransactionDialogFooter} onHide={hideDeleteTransactionDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {transaction && (
                                <span>
                                    Are you sure you want to delete <b>{transaction.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteTransactionsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTransactionsDialogFooter} onHide={hideDeleteTransactionsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {transaction && <span>Are you sure you want to delete the selected transactions?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Transaction;
