import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { useContext, useRef ,useState, useEffect} from 'react';
import { RTLContext } from './App';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { CustomerService } from './service/CustomerService';
import { UserService } from './service/UserService';


const AppInlineMenu = (props) => {
    const inlineMenuRef = useRef(null);
    const isRTL = useContext(RTLContext);
    const menuKey = props.menuKey || 'inline-menu';
    const navigate = useNavigate();

    let emptyUser = {
        id: null,
        customer_id: 0,
        first_name: '',
        last_name: '',
        email: '',
        password: '', // Consider handling password securely
        phone: '',
        rol: 0,
        created: new Date() // Or null, depending on how you handle dates
    };
    let emptyCustomer = {
        customer_id: null,
        name: '',
        address1: '',
        address2: '',
        city: '',
        state_id: null, // You might need to handle state and country IDs carefully
        country_id: null,
        zipcode: '',
        image_data: null, // If you're handling image uploads
        created_at: new Date() // Or null, depending on your date handling
    };

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [customer, setCustomer] = useState(emptyCustomer);    

    const userId = localStorage.getItem('userId');
    const customerId = localStorage.getItem('customerId'); // Retrieve customer_id from local storage

    const userService = new UserService();
    const customerService = new CustomerService();

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        const customerId = localStorage.getItem('customerId');

        if (userId && customerId) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && customerId) {
            customerService.getCustomerById(customerId)
                .then(response => {
                    setCustomer(response.data);
                })
                .catch(error => {
                    console.error('Error fetching customer data:', error);
                });
        }
    }, [isAuthenticated, customerId]);

    useEffect(() => {
        if (isAuthenticated && userId) {
            userService.getUserById(userId)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [isAuthenticated, userId]);
    


    const inlineMenuClassName = classNames(
        'layout-inline-menu',
        {
            'layout-inline-menu-active': props.inlineMenuActive[props.menuKey]
        },
        props.className
    );

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    return (
        <div className={inlineMenuClassName} style={props.style}>
            {isSlim() && <Tooltip target=".avatarTooltip" />}

            <button
                data-pr-tooltip={customer.name}
                className={classNames('avatarTooltip layout-inline-menu-action p-link flex flex-row align-items-center', { 'p-3 lg:p-1 py-3': props.horizontal, 'p-3': !props.horizontal })}
                onClick={(e) => props.onInlineMenuClick(e, menuKey)}
            >
                <img 
                    src={customer.image_data ? `data:image/png;base64,${customer.image_data}` : 'assets/layout/images/user.png'} 
                    alt="avatar" 
                    style={{ width: '32px', height: '32px' }} 
                />
                <span className={classNames('flex flex-column', { 'ml-2': !isRTL, 'mr-2': isRTL })}>
                    <span className="font-bold">{customer.name}</span>
                    <small>{user.email}</small>
                </span>
                <i className={classNames('layout-inline-menu-icon pi pi-angle-down', { 'ml-auto': !isRTL, 'mr-auto': isRTL })}></i>
            </button>

            <CSSTransition nodeRef={inlineMenuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.inlineMenuActive[menuKey]} unmountOnExit>
                <>
                    <ul ref={inlineMenuRef} className="layout-inline-menu-action-panel">
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Settings">
                            <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/pages/account')}>
                                <i className="pi pi-cog pi-fw"></i>
                                <span>Profile</span>
                            </button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Terms of Usage">
                            <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/pages/termsOfUse')}>
                                <i className="pi pi-file pi-fw"></i>
                                <span>Terms of Usage</span>
                            </button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Support">
                            <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/pages/supportPage')}>
                                <i className="pi pi-compass pi-fw"></i>
                                <span>Support</span>
                            </button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Logout">
                            <button className="flex flex-row align-items-center p-link" onClick={() => navigate('/Logout')}>
                                <i className="pi pi-power-off pi-fw"></i>
                                <span>Logout</span>
                            </button>
                        </li>
                    </ul>
                    {isSlim() && <Tooltip target=".tooltip" />}
                </>
            </CSSTransition>
        </div>
    );
};

export default AppInlineMenu;
