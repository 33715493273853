import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="p-grid p-justify-center">
            <div className="p-col-12 p-md-8">
                <div className="card">
                    <h1>Términos de Uso</h1>
                    <p>Bienvenido a CrediUnisa, una plataforma basada en la nube diseñada para ayudarte a almacenar, gestionar y analizar datos de una variedad de sensores en tiempo real. Al utilizar nuestros servicios, aceptas estar sujeto a los siguientes términos y condiciones.</p>

                    <h2>1. Aceptación de los Términos</h2>
                    <p>Al acceder o utilizar CrediUnisa, aceptas cumplir y estar sujeto a estos Términos de Uso, así como a cualquier política, directriz o enmienda que te sea presentada de vez en cuando. Si no estás de acuerdo con estos términos, no debes utilizar nuestros servicios.</p>

                    <h2>2. Descripción del Servicio</h2>
                    <p>CrediUnisa es un servicio en la nube basado en suscripción que permite a los usuarios almacenar, gestionar y analizar datos de varios sensores en tiempo real. Nuestra plataforma ofrece herramientas para la visualización de datos, gestión de proyectos y generación de informes. El servicio es accesible a través de un portal web y está destinado tanto para uso individual como empresarial.</p>

                    <h2>3. Cuenta de Usuario y Seguridad</h2>
                    <p>Para acceder a CrediUnisa, debes crear una cuenta. Eres responsable de mantener la confidencialidad de la información de tu cuenta, incluida tu contraseña. Aceptas notificarnos de inmediato sobre cualquier uso no autorizado de tu cuenta. No somos responsables de ninguna pérdida que sufras debido al acceso no autorizado a tu cuenta.</p>

                    <h2>4. Suscripción y Facturación</h2>
                    <p>El acceso a CrediUnisa requiere una suscripción válida. Las tarifas de suscripción se facturan mensualmente o anualmente, dependiendo del plan que elijas. Aceptas proporcionar información de facturación precisa y completa. El incumplimiento en el pago de las tarifas de suscripción puede resultar en la suspensión o terminación de tu acceso al servicio.</p>

                    <h2>5. Almacenamiento y Gestión de Datos</h2>
                    <p>CrediUnisa te permite almacenar y gestionar datos recopilados de varios sensores. Aunque nos esforzamos por ofrecer un almacenamiento de datos confiable y seguro, reconoces que pueden ocurrir pérdidas de datos y debes mantener copias de respaldo de tus datos. No somos responsables de ninguna pérdida o corrupción de datos.</p>

                    <h2>6. Privacidad y Seguridad de los Datos</h2>
                    <p>Estamos comprometidos con la protección de tus datos. Todos los datos almacenados en CrediUnisa están encriptados y el acceso está restringido solo a usuarios autorizados. Sin embargo, eres responsable de asegurarte de que el uso que haces del servicio cumpla con las leyes y regulaciones de protección de datos aplicables. Te recomendamos que revises nuestra Política de Privacidad para obtener más información sobre cómo manejamos tus datos.</p>

                    <h2>7. Uso Aceptable</h2>
                    <p>Aceptas no utilizar CrediUnisa para ningún propósito ilegal o no autorizado, incluidos, entre otros, la violación de cualquier ley de propiedad intelectual, la distribución de software dañino o la participación en actividades que interrumpan el servicio. Nos reservamos el derecho de suspender o terminar tu cuenta si violas esta política.</p>

                    <h2>8. Disponibilidad y Tiempo de Inactividad del Servicio</h2>
                    <p>Aunque nos esforzamos por mantener una alta disponibilidad, CrediUnisa se proporciona "tal cual" y "según disponibilidad", sin ninguna garantía o promesa de tiempo de actividad. El mantenimiento programado y las actualizaciones pueden resultar en interrupciones temporales del servicio. No somos responsables de ningún daño resultante de las interrupciones del servicio.</p>

                    <h2>9. Derechos de Propiedad Intelectual</h2>
                    <p>Todo el contenido, software y tecnología utilizados en CrediUnisa son propiedad de nuestra empresa y están protegidos por leyes de propiedad intelectual. Se te concede una licencia limitada, no exclusiva e intransferible para utilizar el servicio de acuerdo con estos términos. No puedes copiar, modificar, distribuir o realizar ingeniería inversa de ninguna parte del servicio sin nuestro permiso expreso.</p>

                    <h2>10. Limitación de Responsabilidad</h2>
                    <p>En la medida máxima permitida por la ley, renunciamos a todas las garantías, expresas o implícitas, en relación con el servicio y tu uso del mismo. No somos responsables de ningún daño directo, indirecto, incidental, consecuente o punitivo que surja de tu uso de CrediUnisa.</p>

                    <h2>11. Indemnización</h2>
                    <p>Aceptas indemnizarnos y mantenernos indemnes de cualquier reclamo, daño, pérdida, responsabilidad y gasto (incluidos los honorarios legales) que surjan de tu uso de CrediUnisa, tu violación de estos términos o tu infracción de cualquier derecho de terceros.</p>

                    <h2>12. Terminación</h2>
                    <p>Nos reservamos el derecho de terminar o suspender tu acceso a CrediUnisa en cualquier momento, con o sin previo aviso, por cualquier motivo, incluidos, entre otros, tu incumplimiento de estos términos. Al finalizar, tu derecho a utilizar el servicio cesará inmediatamente.</p>

                    <h2>13. Cambios en los Términos</h2>
                    <p>Podemos actualizar estos Términos de Uso de vez en cuando. Cualquier cambio se publicará en esta página, y tu uso continuado del servicio después de que se hayan realizado dichos cambios constituirá tu aceptación de los nuevos términos.</p>

                    <h2>14. Ley Aplicable</h2>
                    <p>Estos Términos de Uso se rigen y se interpretan de acuerdo con las leyes de [Tu País/Estado], sin tener en cuenta sus principios de conflicto de leyes. Aceptas someterte a la jurisdicción exclusiva de los tribunales ubicados dentro de [Tu Jurisdicción] para resolver cualquier asunto legal que surja de estos términos.</p>

                    <h2>15. Información de Contacto</h2>
                    <p>Si tienes alguna pregunta sobre estos Términos de Uso, por favor contáctanos en support@crediunisa.com.</p>

                    <p>¡Gracias por elegir CrediUnisa! Esperamos que disfrutes usando nuestro servicio.</p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;
