import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { CustomerOldService } from '../service/CustomerOldService';

const CustomerOld = () => {
    let emptyCustomerOld = {
        member_id: null,
        doc_id: '',
        names: '',
        expired_date: null,
        customer_type_id: 0,
    };

    const [customerOlds, setCustomerOlds] = useState([]);
    const [customerOldDialog, setCustomerOldDialog] = useState(false);
    const [deleteCustomerOldDialog, setDeleteCustomerOldDialog] = useState(false);
    const [deleteCustomerOldsDialog, setDeleteCustomerOldsDialog] = useState(false);
    const [customerOld, setCustomerOld] = useState(emptyCustomerOld);
    const [selectedCustomerOlds, setSelectedCustomerOlds] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const customerOldService = new CustomerOldService();
        customerOldService.getCustomerOlds().then((response) => {
            setCustomerOlds(response.data);
        });
    }, []);

    const openNew = () => {
        setCustomerOld(emptyCustomerOld);
        setSubmitted(false);
        setCustomerOldDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCustomerOldDialog(false);
    };

    const hideDeleteCustomerOldDialog = () => {
        setDeleteCustomerOldDialog(false);
    };

    const hideDeleteCustomerOldsDialog = () => {
        setDeleteCustomerOldsDialog(false);
    };

    const saveCustomerOld = () => {
        setSubmitted(true);

        const customerOldService = new CustomerOldService();

        if (customerOld.member_id == null) {
            customerOldService
                .addCustomerOld(customerOld)
                .then((response) => {
                    setCustomerOlds([...customerOlds, response.data]);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Exitoso',
                        detail: 'Cliente antiguo creado',
                        life: 3000,
                    });
                    setCustomerOldDialog(false);
                    setCustomerOld(emptyCustomerOld);
                })
                .catch((error) => {
                    console.error('Error al guardar el cliente antiguo:', error);
                });
        } else {
            customerOldService
                .updateCustomerOld(customerOld.member_id, customerOld)
                .then((response) => {
                    const updatedCustomerOlds = customerOlds.map((c) =>
                        c.member_id === response.data.member_id ? customerOld : c
                    );
                    setCustomerOlds(updatedCustomerOlds);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Exitoso',
                        detail: 'Cliente antiguo actualizado',
                        life: 3000,
                    });
                    setCustomerOldDialog(false);
                    setCustomerOld(emptyCustomerOld);
                })
                .catch((error) => {
                    console.error('Error al actualizar el cliente antiguo:', error);
                });
        }
    };

    const editCustomerOld = (customerOld) => {
        setCustomerOld({ ...customerOld });
        setCustomerOldDialog(true);
    };

    const confirmDeleteCustomerOld = (customerOld) => {
        setCustomerOld(customerOld);
        setDeleteCustomerOldDialog(true);
    };

    const deleteCustomerOld = () => {
        const customerOldService = new CustomerOldService();
        customerOldService
            .deleteCustomerOld(customerOld.member_id)
            .then(() => {
                let updatedCustomerOlds = customerOlds.filter((val) => val.member_id !== customerOld.member_id);
                setCustomerOlds(updatedCustomerOlds);
                setDeleteCustomerOldDialog(false);
                setCustomerOld(emptyCustomerOld);
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Cliente antiguo eliminado', life: 3000 });
            })
            .catch((error) => {
                console.error('Error al eliminar el cliente antiguo:', error);
            });
    };

    const confirmDeleteSelected = () => {
        setDeleteCustomerOldsDialog(true);
    };

    const deleteSelectedCustomerOlds = () => {
        let _customerOlds = customerOlds.filter((val) => !selectedCustomerOlds.includes(val));
        setCustomerOlds(_customerOlds);
        setDeleteCustomerOldsDialog(false);
        setSelectedCustomerOlds(null);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Clientes antiguos eliminados', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _customerOld = { ...customerOld };
        _customerOld[`${name}`] = val;

        setCustomerOld(_customerOld);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCustomerOlds || !selectedCustomerOlds.length} />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={() => dt.current.exportCSV()} />
            </React.Fragment>
        );
    };

    const memberIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.member_id}
            </>
        );
    };

    const docIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Documento</span>
                {rowData.doc_id}
            </>
        );
    };

    const namesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombres</span>
                {rowData.names}
            </>
        );
    };

    const expiredDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha de Expiración</span>
                {new Date(rowData.expired_date).toLocaleDateString('es-ES')}
            </>
        );
    };

    const customerTypeIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tipo de Cliente</span>
                {rowData.customer_type_id}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCustomerOld(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteCustomerOld(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Administrar Clientes Antiguos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const customerOldDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveCustomerOld} />
        </>
    );

    const deleteCustomerOldDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomerOldDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteCustomerOld} />
        </>
    );

    const deleteCustomerOldsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomerOldsDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCustomerOlds} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={customerOlds}
                        selection={selectedCustomerOlds}
                        onSelectionChange={(e) => setSelectedCustomerOlds(e.value)}
                        dataKey="member_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} clientes antiguos"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron clientes antiguos."
                        header={header}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="member_id" header="ID" body={memberIdBodyTemplate}></Column>
                        <Column field="doc_id" header="Documento" body={docIdBodyTemplate}></Column>
                        <Column field="names" header="Nombres" body={namesBodyTemplate}></Column>
                        <Column field="expired_date" header="Fecha de Expiración" body={expiredDateBodyTemplate}></Column>
                        <Column field="customer_type_id" header="Tipo de Cliente" body={customerTypeIdBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog visible={customerOldDialog} style={{ width: '450px' }} header="Detalles del Cliente Antiguo" modal className="p-fluid" footer={customerOldDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="doc_id">Documento</label>
                    <InputText id="doc_id" value={customerOld.doc_id} onChange={(e) => onInputChange(e, 'doc_id')} required />
                </div>
                <div className="field">
                    <label htmlFor="names">Nombres</label>
                    <InputText id="names" value={customerOld.names} onChange={(e) => onInputChange(e, 'names')} required />
                </div>
                <div className="field">
                    <label htmlFor="expired_date">Fecha de Expiración</label>
                    <Calendar id="expired_date" value={new Date(customerOld.expired_date)} onChange={(e) => onInputChange(e, 'expired_date')} showIcon />
                </div>
                <div className="field">
                    <label htmlFor="customer_type_id">Tipo de Cliente</label>
                    <InputText id="customer_type_id" value={customerOld.customer_type_id} onChange={(e) => onInputChange(e, 'customer_type_id')} required />
                </div>
            </Dialog>

            <Dialog visible={deleteCustomerOldDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteCustomerOldDialogFooter} onHide={hideDeleteCustomerOldDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {customerOld && <span>¿Está seguro de que desea eliminar al cliente antiguo <b>{customerOld.names}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deleteCustomerOldsDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteCustomerOldsDialogFooter} onHide={hideDeleteCustomerOldsDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {customerOld && <span>¿Está seguro de que desea eliminar los clientes antiguos seleccionados?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default CustomerOld;
