import React, { useState, useEffect, useRef } from 'react';
import { DataTable, Column, Toast, Button } from 'primereact';
import { Toolbar } from 'primereact/toolbar';
import { PaymentTransactionService } from '../service/PaymentTransactionService';
import { PaymentMethodService } from '../service/PaymentMethodService';
import { CustomerService } from '../service/CustomerService';
import * as XLSX from 'xlsx';

const PaymentTransaction2 = () => {
    const [paymentTransactions, setPaymentTransactions] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        loadValidatedPayments();
        loadPaymentMethods();
        loadCustomers();
    }, []);

    const loadValidatedPayments = () => {
        const paymentTransactionService = new PaymentTransactionService();
        paymentTransactionService.getPayments().then((response) => {
            // Filter only payments with idstatus != 0 (received/validated)
            setPaymentTransactions(response.data.filter(payment => payment.idstatus !== 0));
        });
    };

    const loadPaymentMethods = () => {
        const paymentMethodService = new PaymentMethodService();
        paymentMethodService.getPaymentMethods().then((response) => {
            setPaymentMethods(response.data);
        });
    };

    const loadCustomers = () => {
        const customerService = new CustomerService();
        customerService.getCustomers2().then((response) => {
            setCustomers(response.data);
        });
    };

    const paymentMethodBodyTemplate = (rowData) => {
        const method = paymentMethods.find((method) => method.idmethod === rowData.idmethod);
        return method ? method.description : 'Desconocido';
    };

    const customerNameBodyTemplate = (rowData) => {
        const customer = customers.find(c => c.customer_id === rowData.customer_id);
        return customer ? `${customer.first_name} ${customer.last_name}` : 'Desconocido';
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(paymentTransactions.map(payment => ({
            ID: payment.idrec,
            "Fecha Recibida": new Date(payment.createdate).toLocaleDateString('es-ES'),
            "Fecha Operación": new Date(payment.operationdate).toLocaleDateString('es-ES'),
            "Método de Pago": paymentMethodBodyTemplate(payment),
            "Afiliado": customerNameBodyTemplate(payment),
            Monto: payment.amount,
            Estado: payment.idstatus === 1 ? 'Aprobado' : 'Otro'  // Adjust status labels as needed
        })));

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Pagos Recibidos/Validados");

        // Save the Excel file
        XLSX.writeFile(workbook, "Pagos_Recibidos_Validados.xlsx");
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Pagos Recibidos/Validados</h5>
            <div className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <input type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-inputtext p-component" />
            </div>
            <Button label="Exportar a Excel" icon="pi pi-file-excel" className="p-button-success ml-2" onClick={exportToExcel} />
        </div>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4"></Toolbar>
                    <DataTable
                        ref={dt}
                        value={paymentTransactions}
                        dataKey="idrec"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pagos"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron pagos recibidos/validados."
                        header={header}
                    >
                        <Column field="idrec" header="ID" sortable></Column>
                        <Column field="createdate" header="Fecha Recibida" body={(rowData) => new Date(rowData.createdate).toLocaleDateString('es-ES')} sortable></Column>
                        <Column field="operationdate" header="Fecha Operación" body={(rowData) => new Date(rowData.operationdate).toLocaleDateString('es-ES')} sortable></Column>
                        <Column field="idmethod" header="Método de Pago" body={paymentMethodBodyTemplate} sortable></Column>
                        <Column field="customer_id" header="Afiliado" body={customerNameBodyTemplate} sortable></Column>
                        <Column field="amount" header="Monto" sortable></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default PaymentTransaction2;
