
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ServiceTypeService } from '../service/ServiceTypeService';

const ServiceType = () => {
    let emptyServiceType = {
        service_type_id: null,
        description: '',
        image_data: null,
        fg_publish: 0,
        status: 0,
    };

    const [serviceTypes, setServiceTypes] = useState([]);
    const [serviceTypeDialog, setServiceTypeDialog] = useState(false);
    const [deleteServiceTypeDialog, setDeleteServiceTypeDialog] = useState(false);
    const [deleteServiceTypesDialog, setDeleteServiceTypesDialog] = useState(false);
    const [serviceType, setServiceType] = useState(emptyServiceType);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const serviceTypeService = new ServiceTypeService();
        serviceTypeService.getServiceTypes().then((response) => {
            setServiceTypes(response.data);
        });
    }, []);

    const openNew = () => {
        setServiceType(emptyServiceType);
        setSubmitted(false);
        setServiceTypeDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setServiceTypeDialog(false);
    };

    const hideDeleteServiceTypeDialog = () => {
        setDeleteServiceTypeDialog(false);
    };

    const hideDeleteServiceTypesDialog = () => {
        setDeleteServiceTypesDialog(false);
    };

    const saveServiceType = () => {
        setSubmitted(true);
        if (validateRecord(serviceType)) {
            const serviceTypeService = new ServiceTypeService();
            if (serviceType.service_type_id == null) {
                serviceTypeService.addServiceType(serviceType).then((response) => {
                    setServiceTypes([...serviceTypes, response.data]);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Service Type Created',
                        life: 3000,
                    });
                    setServiceTypeDialog(false);
                    setServiceType(emptyServiceType);
                }).catch((error) => {
                    console.error('Error saving new Service Type:', error);
                });
            } else {
                serviceTypeService.updateServiceType(serviceType, serviceType.service_type_id).then((response) => {
                    const updatedServiceTypes = serviceTypes.map((s) => s.service_type_id === response.data.service_type_id ? serviceType : s);
                    setServiceTypes(updatedServiceTypes);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Service Type Updated',
                        life: 3000,
                    });
                    setServiceTypeDialog(false);
                    setServiceType(emptyServiceType);
                }).catch((error) => {
                    console.error('Error updating Service Type:', error);
                });
            }
        }
    };

    const validateRecord = (record) => {
        return record !== null && record !== undefined;
    };

    const editServiceType = (serviceType) => {
        setServiceType({ ...serviceType });
        setServiceTypeDialog(true);
    };

    const confirmDeleteServiceType = (serviceType) => {
        setServiceType(serviceType);
        setDeleteServiceTypeDialog(true);
    };

    const deleteServiceType = () => {
        const serviceTypeService = new ServiceTypeService();
        serviceTypeService.deleteServiceType(serviceType.service_type_id).then(() => {
            let updatedServiceTypes = serviceTypes.filter((val) => val.service_type_id !== serviceType.service_type_id);
            setServiceTypes(updatedServiceTypes);
            setDeleteServiceTypeDialog(false);
            setServiceType(emptyServiceType);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Service Type Deleted', life: 3000 });
        }).catch((error) => {
            console.error('Error deleting Service Type:', error);
        });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < serviceTypes.length; i++) {
            if (serviceTypes[i].service_type_id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                {rowData.image_data ? (
                    <img src={`data:image/png;base64,${rowData.image_data}`} alt="Service Type Image" className="shadow-2" width="100" />
                ) : (
                    'No Image'
                )}
            </>
        );
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteServiceTypesDialog(true);
    };

    const deleteSelectedServiceTypes = () => {
        let _serviceTypes = serviceTypes.filter((val) => !selectedServiceTypes.includes(val));
        setServiceTypes(_serviceTypes);
        setDeleteServiceTypesDialog(false);
        setSelectedServiceTypes(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Service Types Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _serviceType = { ...serviceType };
        _serviceType[`${name}`] = val;
        setServiceType(_serviceType);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedServiceTypes || !selectedServiceTypes.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.service_type_id}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const fgPublishBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Publish</span>
                {rowData.fg_publish}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editServiceType(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteServiceType(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Service Types</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const serviceTypeDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveServiceType} />
        </>
    );
    const deleteServiceTypeDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteServiceTypeDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteServiceType} />
        </>
    );
    const deleteServiceTypesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteServiceTypesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedServiceTypes} />
        </>
    );

    const onImageSelect = (event) => {
        const file = event.files[0]; // Assuming you allow only one image to be uploaded
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;
                const base64Image = imageDataUrl.split(',')[1]; // Extract the Base64 part
                setServiceType({ ...serviceType, image_data: base64Image });
                setPreviewImage(imageDataUrl);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={serviceTypes}
                        selection={selectedServiceTypes}
                        onSelectionChange={(e) => setSelectedServiceTypes(e.value)}
                        dataKey="service_type_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} service types"
                        globalFilter={globalFilter}
                        emptyMessage="No service types found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="service_type_id" header="ID" body={idBodyTemplate}></Column>
                        <Column field="description" header="Description" sortable body={descriptionBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column header="Image" body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="fg_publish" header="Publish" body={fgPublishBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={serviceTypeDialog} style={{ width: '450px' }} header="Service Type Details" modal className="p-fluid" footer={serviceTypeDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputText id="description" value={serviceType.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': submitted && !serviceType.description })} />
                            {submitted && !serviceType.description && <small className="p-invalid">Description is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="fg_publish">Publish</label>
                            <Dropdown id="fg_publish" value={serviceType.fg_publish} options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]} onChange={(e) => onInputChange(e, 'fg_publish')} />
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <Dropdown id="status" value={serviceType.status} options={[{ label: 'Active', value: 0 }, { label: 'Inactive', value: 1 }]} onChange={(e) => onInputChange(e, 'status')} />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <FileUpload
                                name="demo"
                                chooseLabel="Upload"
                                mode="basic"
                                auto
                                accept="image/png"
                                onSelect={(e) => onImageSelect(e)}
                            />
                            {previewImage && (
                                <div>
                                    <label>Selected Image Preview:</label>
                                    <img src={previewImage} alt="Selected Image" width="150" />
                                </div>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteServiceTypeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteServiceTypeDialogFooter} onHide={hideDeleteServiceTypeDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {serviceType && (
                                <span>
                                    Are you sure you want to delete <b>{serviceType.description}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteServiceTypesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteServiceTypesDialogFooter} onHide={hideDeleteServiceTypesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {serviceType && <span>Are you sure you want to delete the selected service types?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ServiceType;
