import axios from 'axios';

// Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa.com';

// Define the server variable ('local' or 'remote')
const server = 'remote'; // Change this to 'local' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
    baseURL: API_BASE_URL,
});

// Response interceptor
apiClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Handle token expiration (e.g., redirect to login, refresh token, etc.)
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export class CustomerOldService {

    // Helper function to get headers with Authorization token
    getAuthHeaders() {
        const accessToken = localStorage.getItem('accessToken');
        return {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        };
    }

    // Retrieve all CustomerOld records
    getCustomerOlds() {
        return apiClient.get('/customer_old', this.getAuthHeaders());
    }

    // Retrieve a specific CustomerOld by member_id
    getCustomerOldById(memberId) {
        return apiClient.get(`/customer_old/${memberId}`, this.getAuthHeaders());
    }

    // Add a new CustomerOld record
    addCustomerOld(customerData) {
        return apiClient.post('/customer_old', customerData, this.getAuthHeaders());
    }

    // Update an existing CustomerOld record by member_id
    updateCustomerOld(memberId, customerData) {
        return apiClient.put(`/customer_old/${memberId}`, customerData, this.getAuthHeaders());
    }

    // Delete a specific CustomerOld record by member_id
    deleteCustomerOld(memberId) {
        return apiClient.delete(`/customer_old/${memberId}`, this.getAuthHeaders());
    }
}
