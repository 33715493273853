import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { CreditService } from '../service/CreditService';
import { classNames } from 'primereact/utils';

const Credit = () => {
    let emptyCredit = {
        rec_id: null,
        customer_id: '',
        due_date: null,
        closing_date: null,
        amount: 0,
        status: 0,
    };

    const [credits, setCredits] = useState([]);
    const [creditDialog, setCreditDialog] = useState(false);
    const [deleteCreditDialog, setDeleteCreditDialog] = useState(false);
    const [credit, setCredit] = useState(emptyCredit);
    const [selectedCredits, setSelectedCredits] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const creditService = new CreditService();
        creditService.getCredits().then((response) => {
            setCredits(response.data);
        });
    }, []);

    const openNew = () => {
        setCredit(emptyCredit);
        setSubmitted(false);
        setCreditDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setCreditDialog(false);
    };

    const hideDeleteCreditDialog = () => {
        setDeleteCreditDialog(false);
    };

    const saveCredit = () => {
        setSubmitted(true);

        if (credit.customer_id && credit.due_date && credit.closing_date && credit.amount) {
            const creditService = new CreditService();

            // Format dates to the required format
            credit.due_date = formatDate(credit.due_date);
            credit.closing_date = formatDate(credit.closing_date);

            if (credit.rec_id == null) {
                creditService.addCredit(credit).then((response) => {
                    setCredits([...credits, response.data]);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Credit Created',
                        life: 3000,
                    });
                    setCreditDialog(false);
                    setCredit(emptyCredit);
                }).catch((error) => {
                    console.error('Error saving new credit:', error);
                });
            } else {
                creditService.updateCredit(credit, credit.rec_id).then((response) => {
                    const updatedCredits = credits.map((c) => c.rec_id === response.data.rec_id ? credit : c);
                    setCredits(updatedCredits);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Credit Updated',
                        life: 3000,
                    });
                    setCreditDialog(false);
                    setCredit(emptyCredit);
                }).catch((error) => {
                    console.error('Error updating credit:', error);
                });
            }
        }
    };

    const formatDate = (date) => {
        return date.toISOString().slice(0, 19);
    };

    const editCredit = (credit) => {
        setCredit({ ...credit });
        setCreditDialog(true);
    };

    const confirmDeleteCredit = (credit) => {
        setCredit(credit);
        setDeleteCreditDialog(true);
    };

    const deleteCredit = () => {
        const creditService = new CreditService();
        creditService.deleteCredit(credit.rec_id).then(() => {
            let updatedCredits = credits.filter((val) => val.rec_id !== credit.rec_id);
            setCredits(updatedCredits);
            setDeleteCreditDialog(false);
            setCredit(emptyCredit);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Credit Deleted', life: 3000 });
        }).catch((error) => {
            console.error('Error deleting credit:', error);
        });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _credit = { ...credit };
        _credit[`${name}`] = val;
        setCredit(_credit);
    };

    const onDateChange = (e, name) => {
        const val = e.value || '';
        let _credit = { ...credit };
        _credit[`${name}`] = val;
        setCredit(_credit);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedCredits || !selectedCredits.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const confirmDeleteSelected = () => {
        setDeleteCreditDialog(true);
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const recIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.rec_id}
            </>
        );
    };

    const customerIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Customer ID</span>
                {rowData.customer_id}
            </>
        );
    };

    const dueDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Due Date</span>
                {rowData.due_date}
            </>
        );
    };

    const closingDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Closing Date</span>
                {rowData.closing_date}
            </>
        );
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {rowData.amount}
            </>
        );
    };

    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.status}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCredit(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteCredit(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Credits</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const creditDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCredit} />
        </>
    );
    const deleteCreditDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCreditDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCredit} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={credits}
                        selection={selectedCredits}
                        onSelectionChange={(e) => setSelectedCredits(e.value)}
                        dataKey="rec_id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} credits"
                        globalFilter={globalFilter}
                        emptyMessage="No credits found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="rec_id" header="ID" body={recIdBodyTemplate}></Column>
                        <Column field="customer_id" header="Customer ID" body={customerIdBodyTemplate}></Column>
                        <Column field="due_date" header="Due Date" sortable body={dueDateBodyTemplate}></Column>
                        <Column field="closing_date" header="Closing Date" sortable body={closingDateBodyTemplate}></Column>
                        <Column field="amount" header="Amount" sortable body={amountBodyTemplate}></Column>
                        <Column field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={creditDialog} style={{ width: '450px' }} header="Credit Details" modal className="p-fluid" footer={creditDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="customer_id">Customer ID</label>
                            <InputText id="customer_id" value={credit.customer_id} onChange={(e) => onInputChange(e, 'customer_id')} required autoFocus className={classNames({ 'p-invalid': submitted && !credit.customer_id })} />
                            {submitted && !credit.customer_id && <small className="p-invalid">Customer ID is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="due_date">Due Date</label>
                            <Calendar id="due_date" value={credit.due_date} onChange={(e) => onDateChange(e, 'due_date')} dateFormat="yy-mm-dd" showTime showIcon required className={classNames({ 'p-invalid': submitted && !credit.due_date })} />
                            {submitted && !credit.due_date && <small className="p-invalid">Due Date is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="closing_date">Closing Date</label>
                            <Calendar id="closing_date" value={credit.closing_date} onChange={(e) => onDateChange(e, 'closing_date')} dateFormat="yy-mm-dd" showTime showIcon required className={classNames({ 'p-invalid': submitted && !credit.closing_date })} />
                            {submitted && !credit.closing_date && <small className="p-invalid">Closing Date is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="amount">Amount</label>
                            <InputText id="amount" value={credit.amount} onChange={(e) => onInputChange(e, 'amount')} required className={classNames({ 'p-invalid': submitted && !credit.amount })} />
                            {submitted && !credit.amount && <small className="p-invalid">Amount is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="status">Status</label>
                            <InputText id="status" value={credit.status} onChange={(e) => onInputChange(e, 'status')} required className={classNames({ 'p-invalid': submitted && !credit.status })} />
                            {submitted && !credit.status && <small className="p-invalid">Status is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteCreditDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCreditDialogFooter} onHide={hideDeleteCreditDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {credit && (
                                <span>
                                    Are you sure you want to delete <b>{credit.customer_id}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default Credit;
