// Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Perform logout actions here, e.g., clearing user session data
        localStorage.removeItem('accessToken'); // Clear user session data
        navigate('/login'); // Navigate to the login page
    }, [navigate]);

    return null;
};

export default Logout;
