import React, { useRef, useState, useEffect, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { Menu } from 'primereact/menu';
import { Card } from 'primereact/card';
import { ProductService } from '../service/ProductService';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Timeline } from 'primereact/timeline';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RTLContext } from '../App';
import { ServiceService } from '../service/ServiceService';
import { ServiceTypeService } from '../service/ServiceTypeService';
import { CreditService } from '../service/CreditService';
import { TransactionService } from '../service/TransactionService';

const Dashboard = (props) => {
    const [services, setServices] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [credits, setCredits] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [servicesByTypeData, setServicesByTypeData] = useState({});
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const serviceService = new ServiceService();
        const serviceTypeService = new ServiceTypeService();
        const creditService = new CreditService();
        const transactionService = new TransactionService();

        serviceService.getServices().then((response) => {
            setServices(response.data);
        });

        serviceTypeService.getServiceTypes().then((response) => {
            setServiceTypes(response.data);
        });

        creditService.getCredits().then((response) => {
            setCredits(response.data);
        });

        transactionService.getTransactions().then((response) => {
            setTransactions(response.data);
        });
    }, []);

    useEffect(() => {
        const serviceTypeNames = {};
        serviceTypes.forEach((type) => {
            serviceTypeNames[type.service_type_id] = type.description;
        });

        const servicesByType = {};
        services.forEach((service) => {
            if (servicesByType[service.service_type_id]) {
                servicesByType[service.service_type_id]++;
            } else {
                servicesByType[service.service_type_id] = 1;
            }
        });

        const labels = Object.keys(servicesByType).map((key) => serviceTypeNames[key]);
        const data = Object.values(servicesByType);

        setServicesByTypeData({
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#FF9F40',
                        '#4BC0C0',
                        '#9966FF',
                    ],
                    hoverBackgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#FF9F40',
                        '#4BC0C0',
                        '#9966FF',
                    ]
                }
            ]
        });
    }, [services, serviceTypes]);

    const pieOptions = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    fontSize: 14
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    const renderPieChart = () => {
        return (
            <div className="card">
                <h5>Servicios por Tipo</h5>
                <Chart type="pie" data={servicesByTypeData} options={pieOptions} style={{ position: 'relative', width: '100%', height: '300px' }} />
            </div>
        );
    };

    const renderDataTable = () => {
        return (
            <div className="card">
                <h5>Lista de Servicios</h5>
                <DataTable ref={dt} value={services} paginator rows={5} className="datatable-responsive" emptyMessage="No services found.">
                    <Column field="service_id" header="ID" />
                    <Column field="description" header="Descripción" />
                    <Column field="price" header="Precio" />
                    <Column field="price_original" header="Precio Original" />
                    <Column field="service_type_id" header="ID de Tipo de Servicio" />
                    <Column field="status" header="Estado" />
                </DataTable>
            </div>
        );
    };

    return (
        <div className="grid dashboard">
            <div className="col-12 md:col-6 lg:col-3">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-users text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{credits.length}</span>
                            <span className="text-color-secondary mt-2">Afiliados</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-briefcase text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{services.length}</span>
                            <span className="text-color-secondary mt-2">Servicios</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-money-bill text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{credits.length}</span>
                            <span className="text-color-secondary mt-2">Créditos</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 md:col-6 lg:col-3">
                <div className="card">
                    <div className="flex justify-content-between align-items-center p-3">
                        <div>
                            <i className="pi pi-chart-bar text-blue-500 text-6xl"></i>
                        </div>
                        <div className="text-right flex flex-column">
                            <span className="text-4xl">{transactions.length}</span>
                            <span className="text-color-secondary mt-2">Transacciones</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 lg:col-6">
                {renderPieChart()}
            </div>

            <div className="col-12 lg:col-6">
                {renderDataTable()}
            </div>
        </div>
    );
};

export default Dashboard;
