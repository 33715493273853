import axios from 'axios';


// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

export class StateService {

  getAllStates() {
    return apiClient.get('/states');
  }

  getStatesByCountryId(countryId) {
    // Match the endpoint as defined in your Flask app
    return apiClient.get(`/states/${countryId}`);
  }

  // Add other methods as needed
}
