import React, { useState, useEffect, useRef } from 'react';
// Import necessary components and services

import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

import { CustomerService } from '../service/CustomerService';
import { UserService } from '../service/UserService';
import { CountryService } from '../service/CountryService';
import { StateService } from '../service/StateService'; // Adjust the import path as needed
import { Divider } from 'primereact/divider';


const AccountPage = () => {
    let emptyUser = {
        id: null,
        customer_id: 0,
        first_name: '',
        last_name: '',
        email: '',
        password: '', // Consider handling password securely
        phone: '',
        rol: 0,
        created: new Date() // Or null, depending on how you handle dates
    };
    let emptyCustomer = {
        customer_id: null,
        name: '',
        address1: '',
        address2: '',
        city: '',
        state_id: null, // You might need to handle state and country IDs carefully
        country_id: null,
        zipcode: '',
        image_data: null, // If you're handling image uploads
        created_at: new Date() // Or null, depending on your date handling
    };
    
    const [user, setUser] = useState(emptyUser);
    const [customer, setCustomer] = useState(emptyCustomer);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);

    const userId = localStorage.getItem('userId');
    const customerId = localStorage.getItem('customerId'); // Retrieve customer_id from local storage

    const [previewImage, setPreviewImage] = useState(null); // To store the preview image


    // Create instances of UserService and CustomerService
    const userService = new UserService();
    const customerService = new CustomerService();
    const countryService = new CountryService();
    const stateService = new StateService();

    useEffect(() => {
        // Fetch countries
        countryService.getAllCountries()
        .then(response => {
            setCountries(response.data);
        })
        .catch(error => {
            console.error('Error fetching countries:', error);
        });

    }, []); // Empty dependency array means this will run once on mount
    
    useEffect(() => {
        // Fetch states
        stateService.getAllStates()
        .then(response => {
            setStates(response.data);
        })
        .catch(error => {
            console.error('Error fetching states:', error);
        });
    }, []); // This will also run once on mount
    
    useEffect(() => {
        // Fetch user data
        if (userId) {
            userService.getUserById(userId)
                .then(response => {
                    setUser(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        }
    }, [userId]); // Only re-run if userId changes
    
    useEffect(() => {
        // Fetch customer data
        if (customerId) {
            customerService.getCustomerById(customerId)
                .then(response => {
                    setCustomer(response.data);
                    // When the country is fetched, then fetch states for the country
                    if (response.data.country_id && countries.length > 0) {
                        setSelectedCountry(countries.find(c => c.id === response.data.country_id));
                        fetchStatesForCountry(response.data.country_id);
                    }
                })
                .catch(error => {
                    console.error('Error fetching customer data:', error);
                });
        }
    }, [customerId, countries]); // Only re-run if customerId or countries changes
    
    

    const onImageSelect = (event) => {
        const file = event.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageDataUrl = e.target.result;
                const base64Image = imageDataUrl.split(',')[1];
                setCustomer({ ...customer, image_data: base64Image });
                setPreviewImage(imageDataUrl);
            };
            reader.readAsDataURL(file);
        }
    };
    const fetchStatesForCountry = (countryId) => {
        // Assuming StateService has a method to fetch states by countryId
        StateService.getStatesByCountryId(countryId)
            .then(response => {
                setStates(response.data);
                // Set the initial selected state based on customer data
                const customerState = response.data.find(state => state.id === customer.state_id);
                setSelectedState(customerState);
            })
            .catch(error => console.error('Error fetching states:', error));
    };

    const handleSave = () => {
        // Update User
        userService.updateUser(user.id, user)
            .then(response => {
                // Handle successful user update
                console.log("User updated successfully", response.data);
            })
            .catch(error => {
                // Handle user update error
                console.error("Error updating user:", error);
            });

        // Update Customer (including image data)
        customerService.updateCustomer(customer.customer_id, customer)
            .then(response => {
                // Handle successful customer update
                console.log("Customer updated successfully", response.data);
            })
            .catch(error => {
                // Handle customer update error
                console.error("Error updating customer:", error);
            });
    };

    const handleCancel = () => {
        // Implement cancel functionality
    };

    

    const dateBodyTemplate = (rowData, field) => {
        return (
            <React.Fragment>
                {new Intl.DateTimeFormat('en-GB').format(new Date(rowData[field]))} {/* Formatting the date as dd/mm/YYYY */}
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
        
        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Active" : "Inactive"}
        </>
        );    
    };

    // Render the customer's image if available
    const renderCustomerImage = () => {
        if (customer && customer.image_data) {
            const imageSrc = `data:image/png;base64,${customer.image_data}`;
            return <img src={imageSrc} alt="Customer Logo" style={{ maxWidth: '150px', maxHeight: '150px' }} />;
        }
        return <p>No logo</p>;
    };

    return (
        <div className="account-page">
            <div className="logo-upload-container">
                {previewImage ? (
                    <img src={previewImage} alt="Customer Logo" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                ) : (
                    renderCustomerImage()
                )}
                <FileUpload
                    name="demo"
                    chooseLabel="Upload Logo"
                    mode="basic"
                    auto
                    accept="image/*"
                    onSelect={onImageSelect}
                />
            </div>

            {user && (
                <div className="user-info-form p-fluid">
                    <h2>User Information</h2>
                    <div className="p-field">
                        <label htmlFor="firstname">First Name</label>
                        <InputText id="firstname" type="text" value={user.first_name} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="lastname">Last Name</label>
                        <InputText id="lastname" type="text" value={user.last_name} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="email">Email</label>
                        <InputText id="email" type="text" value={user.email} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="phone">Phone</label>
                        <InputText id="phone" type="text" value={user.phone} />
                    </div>
                </div>
            )}

            {customer && (
                <div className="customer-info-form p-fluid">
                    <h2>Customer Information</h2>
                    <div className="p-field">
                        <label htmlFor="companyname">Company Name</label>
                        <InputText id="companyname" type="text" value={customer.name} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="address1">Address 1</label>
                        <InputText id="address1" type="text" value={customer.address1} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="address2">Address 2</label>
                        <InputText id="address2" type="text" value={customer.address2} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="city">City</label>
                        <InputText id="city" type="text" value={customer.city} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="country">Country</label>
                        <Dropdown 
                            id="country" 
                            value={selectedCountry}
                            options={countries}
                            onChange={(e) => {
                                setSelectedCountry(e.value);
                                fetchStatesForCountry(e.value.id); // Assuming e.value is the country object
                            }}
                            optionLabel="name"
                            placeholder="Select a Country" 
                        />
                    </div>
                    <Dropdown 
                        id="state" 
                        value={selectedState}
                        options={states}
                        onChange={(e) => setSelectedState(e.value)}
                        optionLabel="name"
                        placeholder="Select a State" 
                    />
                    {/* Add other fields as needed */}
                </div>
            )}

            
            <Divider />
            {/* Save Button */}
            <Button label="Save" icon="pi pi-check" className="p-button-success" onClick={handleSave} />

        </div>
    );
};

export default AccountPage;