import axios from 'axios';

// // Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class UserService {

    // Helper function to get headers with Authorization token
    getAuthHeaders() {
        const accessToken = localStorage.getItem('accessToken');
        return {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
        };
    }

    getUsers() {
        return apiClient.get(`/users`, this.getAuthHeaders());
    }

    getUserById(userId) {
        return apiClient.get(`/users/${userId}`, this.getAuthHeaders());
    }

    addUser(userData) {
        return apiClient.post(`/users`, userData, this.getAuthHeaders());
    }

    updateUser(userId, userData) {
        return apiClient.put(`/users/${userId}`, userData, this.getAuthHeaders());
    }

    deleteUser(userId) {
        return apiClient.delete(`/users/${userId}`, this.getAuthHeaders());
    }

    getUserByCustomerId(customerId) {
      return apiClient.get(`/userbycust?customer_id=${customerId}`, this.getAuthHeaders());
  }
}
