// import React from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { login, isAuthenticated } from '../service/LoginService';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const goDashboard = () => {
        // navigate('/uikit/dataLayoutGauge');
        navigate('/dashboard');
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const data = await login(email, password);
          localStorage.setItem('accessToken', data.access_token);
          localStorage.setItem('customerId', data.customer_id);  // Storing the customer ID
          localStorage.setItem('userId', data.user_id);  // Storing the customer ID
          goDashboard();
        } catch (error) {
          setError(error.message);
        }
    };

    return (
        <div className="pages-body login-page flex flex-column">
            {/* <div className="topbar p-3 flex justify-content-between flex-row align-items-center">
                <div className="topbar-left ml-3 flex">
                    <div className="logo">
                        <img src="assets/layout/images/logo-crediunisa03.svg" alt="" />
                    </div>
                </div>
            </div> */}
     
            
            <div className="align-self-center mt-auto mb-auto">
                <img src="assets/layout/images/logo-crediunisa03.svg" alt="" style={{ marginBottom: '20px' }} /> {/* Add this line */}
                <div className="pages-panel card flex flex-column">
                    <div className="pages-header px-3 py-1">
                        <h2>Inicio de sesión</h2>
                    </div>
                    
                    <h4>Bienvenido</h4>

                    <div className="pages-detail mb-6 px-6">Utilice sus credenciales para acceder al portal administrativo de CreditUnisa</div>

                    <div className="input-panel flex flex-column px-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            <span className="p-float-label">
                                {/* <InputText type="text" id="inputgroup1" name="email"/> */}
                                <InputText
                                    type="text"
                                    id="inputgroup1"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <label htmlFor="inputgroup1">Email</label>
                            </span>
                        </div>

                        <div className="p-inputgroup mt-3 mb-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <span className="p-float-label">
                                {/* <InputText type="password" id="inputgroup2" name="password"/> */}
                                <InputText
                                    type="password"
                                    id="inputgroup2"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <label htmlFor="inputgroup2">Contraseña</label>
                            </span>
                        </div>
                    </div>

                    {/* <Button className="login-button mb-6 px-3" label="LOGIN"></Button> */}
                    <form onSubmit={handleSubmit}>
                        {/* ... */}
                        {error && <div className="p-invalid">{error}</div>}
                        <Button className="login-button mb-6 px-3" label="Entrar"></Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
