import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { CustomerService } from '../service/CustomerService';
import { useNavigate } from 'react-router-dom';

const Client = () => {
    const [customers, setCustomers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const customerService = new CustomerService();
        customerService.getCustomers().then((response) => {
            setCustomers(response.data);
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch customers', life: 3000 });
        });
    }, []);

    const onDetail = (rowData) => {
        navigate(`/clientdetail/${rowData.customer_id}`);
    };

    const header = (
        <div className="table-header">
            <h5 className="mx-0 my-1">Gestionar Afiliados</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <Button label="Detalles" icon="pi pi-info-circle" className="p-button-secondary" onClick={() => onDetail(rowData)} />
        );
    };

    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <div className="card">
                <DataTable value={customers} paginator rows={10} dataKey="customer_id" globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                    <Column field="customer_id" header="ID" sortable />
                    <Column field="first_name" header="Nombre" sortable />
                    <Column field="last_name" header="Apellido" sortable />
                    <Column field="email" header="Email" sortable />
                    <Column field="phone" header="Teléfono" sortable />
                    <Column field="city" header="Ciudad" sortable />
                    <Column field="zipcode" header="Código Postal" sortable />
                    <Column body={actionBodyTemplate} header="Acciones" />
                </DataTable>
            </div>
        </div>
    );
};

export default Client;
