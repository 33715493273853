import axios from 'axios';

// Define the base URLs for the local and remote servers
const LOCAL_API_BASE_URL = 'http://localhost:5002';
const REMOTE_API_BASE_URL = 'https://api.crediunisa.com';

// Define the server variable ('local' or 'remote')
const server = 'remote';  // Change this to 'remote' when needed

// Define the base URL based on the server variable
const API_BASE_URL = server === 'local' ? LOCAL_API_BASE_URL : REMOTE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL
});

// Response interceptor
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration (e.g., redirect to login, refresh token, etc.)
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export class CreditService {
  // Helper function to get headers with Authorization token
  getAuthHeaders() {
    const accessToken = localStorage.getItem('accessToken');
    return {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    };
  }

  getCredits() {
    return apiClient.get('/credits', this.getAuthHeaders());
  }

  addCredit(credit) {
    return apiClient.post('/credits', credit, this.getAuthHeaders());
  }

  updateCredit(credit, rec_id) {
    return apiClient.put(`/credits/${rec_id}`, credit, this.getAuthHeaders());
  }

  deleteCredit(rec_id) {
    return apiClient.delete(`/credits/${rec_id}`, this.getAuthHeaders());
  }

  getCreditById(rec_id) {
    return apiClient.get(`/credits/${rec_id}`, this.getAuthHeaders());
  }

  getCreditsByCustomerId(customerId) {
    return apiClient.get(`/credits?customer_id=${customerId}`, this.getAuthHeaders());
  }


  getCreditSettings() {
    return apiClient.get('/credit_settings', this.getAuthHeaders());
  }

  getCreditSettingByDate(day) {
    return apiClient.get(`/credit_settings?day=${day}`, this.getAuthHeaders());
  }
}
