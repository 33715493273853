import React, { useState, useEffect, useRef } from 'react';
import { DataTable, Column, Toast, Button, Dialog } from 'primereact';
import { Toolbar } from 'primereact/toolbar';
import { PaymentTransactionService } from '../service/PaymentTransactionService';
import { PaymentMethodService } from '../service/PaymentMethodService';
import { CustomerService } from '../service/CustomerService';

const PaymentTransaction = () => {
    const [paymentTransactions, setPaymentTransactions] = useState([]);
    const [approveDialog, setApproveDialog] = useState(false);
    const [selectedPaymentTransaction, setSelectedPaymentTransaction] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        loadPendingPayments();
        loadPaymentMethods();
        loadCustomers();
    }, []);

    const loadPendingPayments = () => {
        const paymentTransactionService = new PaymentTransactionService();
        paymentTransactionService.getPayments().then((response) => {
            // Filter only payments with idstatus = 0 (pending for approval)
            setPaymentTransactions(response.data.filter(payment => payment.idstatus === 0));
        });
    };

    const loadPaymentMethods = () => {
        const paymentMethodService = new PaymentMethodService();
        paymentMethodService.getPaymentMethods().then((response) => {
            setPaymentMethods(response.data);
        });
    };

    const loadCustomers = () => {
        const customerService = new CustomerService();
        customerService.getCustomers2().then((response) => {
            setCustomers(response.data);
        });
    };

    const approvePayment = () => {
        if (!selectedPaymentTransaction) return;

        const paymentTransactionService = new PaymentTransactionService();
        const updatedPayment = {
            ...selectedPaymentTransaction,
            idstatus: 1, // Approved
            approvaldate: new Date().toISOString(), // Current time
            iduserapproval: localStorage.getItem('userId') // Assume userId is stored in localStorage
        };

        paymentTransactionService
            .updatePayment(updatedPayment.idrec, updatedPayment)
            .then(() => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Exitoso',
                    detail: 'Pago Aprobado',
                    life: 3000,
                });
                setApproveDialog(false);
                loadPendingPayments(); // Refresh the datatable after approval
            })
            .catch((error) => {
                console.error('Error al aprobar el Pago:', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error al aprobar el pago',
                    life: 3000,
                });
            });
    };

    const confirmApprovePayment = (paymentTransaction) => {
        setSelectedPaymentTransaction(paymentTransaction);
        setApproveDialog(true);
    };

    const hideApproveDialog = () => {
        setApproveDialog(false);
    };

    const paymentMethodBodyTemplate = (rowData) => {
        const method = paymentMethods.find((method) => method.idmethod === rowData.idmethod);
        return method ? method.description : 'Desconocido';
    };

    const customerNameBodyTemplate = (rowData) => {
        const customer = customers.find(c => c.customer_id === rowData.customer_id);
        return customer ? `${customer.first_name} ${customer.last_name}` : 'Desconocido';
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                label="Aprobar"
                icon="pi pi-check"
                className="p-button-rounded p-button-success"
                onClick={() => confirmApprovePayment(rowData)}
            />
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Pagos Pendientes de Aprobación</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <input type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." className="p-inputtext p-component" />
            </span>
        </div>
    );

    const approveDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideApproveDialog} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={approvePayment} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4"></Toolbar>
                    <DataTable
                        ref={dt}
                        value={paymentTransactions}
                        dataKey="idrec"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pagos"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron pagos pendientes."
                        header={header}
                    >
                        <Column field="idrec" header="ID" sortable></Column>
                        <Column field="createdate" header="Fecha Recibida" body={(rowData) => new Date(rowData.createdate).toLocaleDateString('es-ES')} sortable></Column>
                        <Column field="operationdate" header="Fecha Operación" body={(rowData) => new Date(rowData.operationdate).toLocaleDateString('es-ES')} sortable></Column>
                        <Column field="idmethod" header="Método de Pago" body={paymentMethodBodyTemplate} sortable></Column>
                        <Column field="customer_id" header="Afiliado" body={customerNameBodyTemplate} sortable></Column>
                        <Column field="amount" header="Monto" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            {/* Approve Payment Dialog */}
            <Dialog visible={approveDialog} style={{ width: '450px' }} header="Confirmar Aprobación" modal footer={approveDialogFooter} onHide={hideApproveDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {selectedPaymentTransaction && <span>¿Está seguro que desea confirmar este pago?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default PaymentTransaction;
