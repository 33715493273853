import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { PaymentMethodService } from '../service/PaymentMethodService';

const PaymentMethod = () => {
    let  emptyPaymentMethod = {
        payment_method_id: null,
        stripe_payment_method_id:'',
        type_name: '',
        description: '',
        status: 0,
        created_at: new Date(),
        // Add other fields as needed
    };

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodDialog, setPaymentMethodDialog] = useState(false);
    const [deletePaymentMethodDialog, setDeletePaymentMethodDialog] = useState(false);
    const [deletePaymentMethodsDialog, setDeletePaymentMethodsDialog] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(emptyPaymentMethod);
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    // const paymentMethodService = useRef(new PaymentMethodService());

    const statusOptions = [
        {label: 'Open', value: 0},
        {label: 'Close', value: 1},
    ];

    useEffect(() => {
        const paymentMethodService = new PaymentMethodService();
        paymentMethodService.getPaymentMethods().then((response) => {
            setPaymentMethods(response.data);
        });
    }, []);

    const openNew = () => {
        setPaymentMethod(emptyPaymentMethod);
        setSubmitted(false);
        setPaymentMethodDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPaymentMethodDialog(false);
    };

    const hideDeletePaymentMethodDialog = () => {
        setDeletePaymentMethodDialog(false);
    };

    const hideDeletePaymentMethodsDialog = () => {
        setDeletePaymentMethodsDialog(false);
    };
    
    
    const savePaymentMethod = () => {
        setSubmitted(true);
    
        // if (paymentMethod.name.trim() && paymentMethod.image) { // Check if an image is selected+
        if (validateRecord(paymentMethod)) {
            
            const paymentMethodService = new PaymentMethodService();
            // const isNewPaymentMethod = !paymentMethod.payment_method_id;
            if (paymentMethod.payment_method_id==null) {
                
                paymentMethodService
                    .addPaymentMethod(paymentMethod)
                    .then((response) => {
                        // Handle the response for a new subscriber
                        // setPaymentMethods([...paymentMethods, response.data]);
                        setPaymentMethods([...paymentMethods, paymentMethod]);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Subscription Type Created',
                            life: 3000,
                        });
                        setPaymentMethodDialog(false);
                        setPaymentMethod(emptyPaymentMethod);
                    })
                    .catch((error) => {
                        console.error('Error saving new PaymentMethod:', error);
                    });
            } else {
                
                paymentMethodService
                    .updatePaymentMethod(paymentMethod, paymentMethod.payment_method_id)
                    .then((response) => {
                        // Handle the response for an updated subscriber
                        const updatedPaymentMethods = paymentMethods.map((s) =>
                            s.payment_method_id === response.data.payment_method_id ? paymentMethod : s
                        );
                        setPaymentMethods(updatedPaymentMethods);
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'PaymentMethod Updated',
                            life: 3000,
                        });
                        setPaymentMethodDialog(false);
                        setPaymentMethod(emptyPaymentMethod);
                    })
                    .catch((error) => {
                        console.error('Error updating PaymentMethod:', error);
                    });
            }
        }
    };

    const validateRecord = (subscription) => {
        // Check if the subscription object is not null
        return subscription !== null && subscription !== undefined;
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < paymentMethods.length; i++) {
            if (paymentMethods[i].payment_method_id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const editPaymentMethod = (paymentMethod) => {
        setPaymentMethod({ ...paymentMethod });
        setPaymentMethodDialog(true);
    };

    const confirmDeletePaymentMethod = (paymentMethod) => {
        setPaymentMethod(paymentMethod);
        setDeletePaymentMethodDialog(true);
    };

    const deletePaymentMethod = () => {
        const paymentMethodService = new PaymentMethodService();
        paymentMethodService
            .deletePaymentMethod(paymentMethod.payment_method_id)
            .then(() => {
                // Delete the subscription from the local state
                let updatedSubscriptions = paymentMethods.filter((val) => val.payment_method_id !== paymentMethod.payment_method_id);
                setPaymentMethods(updatedSubscriptions);
                setDeletePaymentMethodDialog(false);
                setPaymentMethod(emptyPaymentMethod);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscription Type Deleted', life: 3000 });
            })
            .catch((error) => {
                console.error('Error deleting Subscription Type:', error);
                // Handle any error in the API call
            });

    };

    
    
    
    const formatDate = (value) => {
        if (value) {
            const date = new Date(value); // Convert string to Date object
            return date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
        return ''; // Return an empty string if value is not defined
    }

    
    const exportCSV = () => {
        dt.current.exportCSV();
    };


    const confirmDeleteSelected = () => {
        setDeletePaymentMethodsDialog(true);
    };

    const deleteSelectedPaymentMethods = () => {
        let _paymentMethods = paymentMethods.filter((val) => !selectedPaymentMethods.includes(val));
        setPaymentMethods(_paymentMethods);
        setDeletePaymentMethodsDialog(false);
        setSelectedPaymentMethods(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Subscriptions Type Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _paymentMethod = { ...paymentMethod };
        _paymentMethod[`${name}`] = val;

        setPaymentMethod(_paymentMethod);
    };

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _paymentMethod = { ...paymentMethod };
        _paymentMethod[`${name}`] = val;

        setPaymentMethod(_paymentMethod);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPaymentMethods || !selectedPaymentMethods.length} />
                </div>
            </React.Fragment>
        );

    };
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const idBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.payment_method_id}
            </>
        );
    };
    const stripeidBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Stripe Id</span>
                {rowData.stripe_payment_method_id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.type_name}
            </>
        );
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const daysBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Days</span>
                {rowData.days}
            </>
        );
    };
    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {rowData.price}
            </>
        );
    };
    const maxGaugesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Max Gauges</span>
                {rowData.max_gauges}
            </>
        );
    };


    const statusBodyTemplate = (rowData) => {
        
        const iconClassName = classNames("pi", {
            "pi-circle-on": rowData.status === 0,
            "pi-check": rowData.status === 1
        });
    
        return (
        <>
            <span className="p-column-title {iconClassName}">Status</span>
            {rowData.status === 0 ? "Open" : "Close"}
        </>
        );    
    };
    
    const createdDateBodyTemplate = (rowData) => {
        const formattedDate = formatDate(rowData.created_at);
        
        return (
            <>
            <span className="p-column-title">Created Date</span>
            {formattedDate}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        const buttonGroupStyle = {
            display: 'flex',      // Make the buttons display in a row
            justifyContent: 'space-between', // Distribute space between buttons
        };
    
        const buttonStyle = {
            flex: '1',            // Allow buttons to grow and share available space
            marginRight: '2px',   // Adjust spacing between buttons
        };
        return (
            <div className="actions" style={buttonGroupStyle}>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-rounded p-button-success" 
                    style={buttonStyle}
                    onClick={() => editPaymentMethod(rowData)} />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-warning" 
                    style={buttonStyle}
                    onClick={() => confirmDeletePaymentMethod(rowData)} />
            </div>
        );
    };

    
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Subscriptions Types</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const paymentMethodDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePaymentMethod} />
        </>
    );

    const deletePaymentMethodDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentMethodDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePaymentMethod} />
        </>
    );

    const deletePaymentMethodsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePaymentMethodsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPaymentMethods} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <DataTable 
                        ref={dt}
                        value={paymentMethods} 
                        selection={selectedPaymentMethods} 
                        onSelectionChange={(e) => setSelectedPaymentMethods(e.value)}
                        dataKey="payment_method_id" 
                        paginator 
                        rows={10} 
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} subscriptions types"
                        globalFilter={globalFilter}
                        emptyMessage="No Subscription Type found."
                        header={header}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                            
                            <Column field="payment_method_id" header="ID" body={idBodyTemplate}></Column>
                            <Column field="stripe_payment_method_id" header="Stripe ID" body={stripeidBodyTemplate}></Column>
                            <Column field="type_name" header="Name" body={nameBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                            <Column field="description" header="Description" body={descriptionBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                            <Column field="status" header="status" body={statusBodyTemplate} sortable headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                            <Column field="created_at" header="Created Date" sortable body={createdDateBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            
            <Dialog visible={paymentMethodDialog} style={{ width: '450px' }} header="Subscription Type Details" modal className="p-fluid" footer={paymentMethodDialogFooter} onHide={hideDialog}>
                <div className="field">
                    <label htmlFor="stripeid">StripeId</label>
                    <InputText id="stripeid" value={paymentMethod.stripe_payment_method_id} onChange={(e) => onInputChange(e, 'stripe_payment_method_id')} required  cols={20} />
                </div>

                <div className="field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" value={paymentMethod.type_name} onChange={(e) => onInputChange(e, 'type_name')} required  cols={20} />
                </div>

                <div className="field">
                    <label htmlFor="description">Description</label>
                    <InputText id="description" value={paymentMethod.description} onChange={(e) => onInputChange(e, 'description')} required />
                    {submitted && !paymentMethod.description && <small className="p-error">Description is required.</small>}
                </div>

                <div className="field">
                    <label htmlFor="status">Status</label>
                    <Dropdown id="status" value={paymentMethod.status} options={statusOptions} onChange={(e) => onInputChange(e, 'status')} />
                </div>

            </Dialog>

            <Dialog visible={deletePaymentMethodDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentMethodDialogFooter} onHide={hideDeletePaymentMethodDialog}>
                <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                    {paymentMethod && <span>Are you sure you want to delete the subscription type <b>{paymentMethod.type_name}</b>?</span>}
                </div>
            </Dialog>

            <Dialog visible={deletePaymentMethodsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentMethodsDialogFooter} onHide={hideDeletePaymentMethodsDialog}>
                <div className="flex align-items-center justify-content-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {paymentMethod && <span>Are you sure you want to delete the selected subscription Types?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default PaymentMethod;
